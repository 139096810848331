import React from 'react';

import './App.scss';

import ABOUT_ME from './about-me';
import Row from './Row';
import TerminalPrefix from './TerminalPrefix';

export const VALID_COMMANDS = [
	'help'
];

function HistoryItemRenderer({historyItem, i}) {
	return (
		<>
			{historyItem.input !== undefined &&
				<div className="row">
					<TerminalPrefix />
					<span className="text">{historyItem.input}</span>
				</div>
			}

			{historyItem.type === 'notFound' &&
				<>
					<Row>{`command not found: ${historyItem.input}`}</Row>
					<Row indent>{'try: help'}</Row>
				</>
			}

			{historyItem.type === 'help' &&
				<>
					<Row>{'Commands:'}</Row>

					{VALID_COMMANDS.map(key => (
						<Row indent key={key}>{key}</Row>
					))}
				</>
			}

			{historyItem.type === 'help' && Object.keys(ABOUT_ME).map(key => (
				<Row indent key={key}>{key}</Row>
			))}

			{ABOUT_ME[historyItem.type] &&
				ABOUT_ME[historyItem.type]
			}
		</>
	);
}

export default HistoryItemRenderer;
