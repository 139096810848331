import React from 'react';

import './TerminalPrefix.scss';

function App() {
	return (
		<div className="terminal-prefix">
			<span className="arrow">➜ </span>
			<span className="location">evanthibodeau.com </span>
			<span className="git">git:(</span>
			<span className="branch">master</span>
			<span className="git">)</span>
			<span className="ex"> ✗ </span>
		</div>
	);
}

export default App;
