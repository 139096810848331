import React from 'react';

function Link(props) {
	const {children, href, ...otherProps} = props;

	return (
		<a href={href} rel="noopener noreferrer" target="_blank" {...otherProps}>
			{children}
		</a>
	);
}

export default Link;