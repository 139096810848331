import React, {useEffect, useState} from 'react';

import './App.scss';

import HistoryItemRenderer from './HistoryItemRenderer';
import TerminalPrefix from './TerminalPrefix';
import TerminalInput from './TerminalInput';

function App() {
	const [terminalHistory, setTerminalHistory] = useState([]);

	const handleEnter = (obj) => {
		const newRows = [
			obj
		];

		setTerminalHistory([
			...terminalHistory,
			...newRows
		]);
	}

	useEffect(() => {
		window.scroll(0, window.innerHeight);
	}, [terminalHistory.length]);

	return (
		<div className="App">
			{terminalHistory.map((historyItem, i) => (
				<HistoryItemRenderer historyItem={historyItem} key={i} />
			))}

			<div className="row">
				<TerminalPrefix />

				<TerminalInput onEnter={handleEnter} />
			</div>
		</div>
	);
}

export default App;
