import React, {useEffect, useRef, useState} from 'react';

import './TerminalInput.scss';

import ABOUT_ME from './about-me';
import {VALID_COMMANDS} from './HistoryItemRenderer';

const KEY_CODE_ENTER = 13;

function TerminalInput({onEnter}) {
	const [value, setValue] = useState('');

	const inputEl = useRef(null);

	useEffect(() => {
		const handleFocus = e => {
			inputEl.current.focus();
		};

		window.addEventListener('keydown', handleFocus);
		
		return () => window.removeEventListener('keydown', handleFocus);
	}, []);

	useEffect(() => {
		const handleFocus = e => {
			inputEl.current.focus();
		};

		window.addEventListener('click', handleFocus);
		
		return () => window.removeEventListener('click', handleFocus);
	}, []);

	const handleInputKeyDown = e => {
		if (e.keyCode === KEY_CODE_ENTER) {
			const trimmedValue = value.trim();

			if (Object.keys(ABOUT_ME).includes(trimmedValue) || VALID_COMMANDS.includes(trimmedValue)) {
				onEnter({
					input: value,
					type: trimmedValue
				});
			}
			else if(trimmedValue === '') {
				onEnter({input: value});
			}
			else {
				onEnter({
					input: value,
					type: 'notFound'
				});
			}

			setValue('');
		}
	}

	return (
		<>
			<input
				autoFocus
				className="terminal-input text"
				onChange={e => setValue(e.target.value)}
				onKeyDown={e => handleInputKeyDown(e)}
				ref={inputEl}
				spellCheck="false"
				type="text"
				value={value}
			/>
		</>
	);
}

export default TerminalInput;