import React from 'react';

import Link from './Link';
import Row from './Row';

const ABOUT_ME = {
	about: [
		<Row>{'Born in Alaska, studied in California, and living in'}</Row>,
		<Row>
			{'Dubai. Married to Joella. Trying to follow '}
			<Link href="https://www.esv.org/John+14:6/">Jesus</Link>
			{'.'}
		</Row>
	],
	email: [
		<Row>
			{'personal: '}
			<a href="mailto:evan.m.thibodeau@gmail.com">evan.m.thibodeau@gmail.com</a>
		</Row>,
		<Row>
			{'Work: '}
			<a href="mailto:evan.thibodeau@liferay.com">evan.thibodeau@liferay.com</a>
		</Row>
	],
	github: [
		<Row>
			<Link href="https://github.com/ethib137">ethib137</Link>
		</Row>
	],
	location: [
		<Row>
			<Link href="https://www.google.com/maps/place/Dubai">{'Dubai, UAE'}</Link>
		</Row>
	],
	name: [
		<Row>{'Evan Thibodeau'}</Row>
	],
	work: [
		<Row>
			{'Staff Software Engineer & Team Lead @ '}
			<Link href="https://www.liferay.com/">Liferay</Link>
		</Row>
	],
};

export default ABOUT_ME;