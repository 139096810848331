import React from 'react';

function Row(props) {
	const {children, indent, ...otherProps} = props;
	return (
		<div className={`${indent ? 'ml-4 ' : ''}row`} {...otherProps}>
			<span className="text">{children}</span>
		</div>
	);
}

export default Row;